<template>
  <main>
    <router-view/>

    <notifications position="bottom right" />
  </main>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: null,
      roles: null,
      selectedLanguage: localStorage.getItem('selectedLanguage') || 'en', // Default language
      selectedCustomer: localStorage.getItem('xpertcustomerselected'), // Default language
      customerType: localStorage.getItem('xpertcustomertype'), // Default language
      supportedLanguages: ['en', 'nl', 'fr'], // List of supported languages
      unreadCount: 0,
      unreadCountBookings: 0
    };
  },
  computed: {
    // Compute the current environment
    environment() {
      return process.env.NODE_ENV;
    },
    // Check if the environment is not production
    isNotProduction() {
      return process.env.NODE_ENV !== 'production';
    }
  },
  methods: {
    changeLanguage() {
      // Update the selected language in localStorage
      localStorage.setItem('selectedLanguage', this.selectedLanguage);
      // Update the language in vue-i18n
      this.$i18n.locale = this.selectedLanguage;
    },
    changeCustomer() {
      localStorage.setItem('xpertcustomerselected', this.selectedCustomer);

      const token = localStorage.getItem('xperttoken');
      const instance = axios.create({
        baseURL: this.$baseURL,
        headers: {
          Authorization: `Bearer ${token}`,
          'Customer': localStorage.getItem('xpertcustomerselected')
        }
      });

      /* Get settings */
      instance.get('customer/type')
        .then(response => {
            this.customerType = response.data.type;
            localStorage.setItem('xpertcustomertype', response.data.type);

            location.reload();
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            // Handle the error
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = localStorage.getItem('xpertrole');

    // Check if the current route path contains "/admin"
    if (window.location.href.includes("/admin") && role && role != 1) {
      // Redirect to the home page or any other desired route
      next({ path: "/" });
    } else {
      next();
    }
  },
  mounted() {
    // Function to position the dropdown menu
    const positionDropdownMenu = () => {
        const avatarElement = document.querySelector('.avatar');

        if (avatarElement) {
            // Get the bounding client rect of the avatar element
            const rect = avatarElement.getBoundingClientRect();

            // Calculate the right position from the right side of the screen
            const rightPositionFromRight = window.innerWidth - rect.right;

            const menuDropdown = document.querySelector('.menu.menu-sub.menu-sub-dropdown');

            // Check if the menuDropdown element is found in the DOM
            if (menuDropdown) {
                // Set the top and right positions
                menuDropdown.style.position = 'absolute'; // Ensures it is positioned absolutely
                menuDropdown.style.top = '75px';          // Adjust the top position as needed
                menuDropdown.style.right = rightPositionFromRight + 'px'; // Adjust the right position as needed
            }

            console.log(menuDropdown);
            console.log(rightPositionFromRight);
        }
    };

    setTimeout(function() {
      positionDropdownMenu();
    }, 1500);
    
    document.addEventListener("DOMContentLoaded", () => {
      const element = document.getElementById('app');

      // Call positionDropdownMenu on page load
      positionDropdownMenu();

      // ResizeObserver to monitor changes in dimensions
      const resizeObserver = new ResizeObserver(entries => {
          entries.forEach(entry => {
              for (let i = 0; i <= 1; i++) {
                positionDropdownMenu();

                setTimeout(function() {
                  let { width, height } = entry.contentRect;
                  let data = {
                      width: width,
                      height: height
                  };

                  window.parent.postMessage(data, "*"); // "*" allows sending to any origin, adjust as needed
                }, 500 * (i + 1));
              }
          });
      });

      if (element) {
          resizeObserver.observe(element);
      }
    });


    const currentUrl = window.location.href;
    const containsIframe = currentUrl.indexOf("iframe") !== -1;

    if(containsIframe || this.$route.query.customer) {
      if(this.$route.query.customer) {
        localStorage.setItem('xpertcustomerselected', this.$route.query.customer);
      } else {
        localStorage.setItem('xpertcustomerselected', 1);
      }
      
      localStorage.setItem('xpertcustomertype', 1);
      localStorage.setItem('xperttoken', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNGQ3OTlhZWJlM2YzNTIxOGNiZTdlNTQwNzQyODBkMWU4NDJkOGUyYjZiYWM1NjE4ZmY4N2U0ZmNlOWRjOGUzMzBjMGZkNWRjYjM0OWU5NzEiLCJpYXQiOjE3MTMyNzA2OTkuMTc0Mzg0LCJuYmYiOjE3MTMyNzA2OTkuMTc0MzkxLCJleHAiOjE3NDQ4MDY2OTkuMTcwNjg3LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.DvlRsQxqIwV1-ld3ycYhomf0Fz4lmo9naXl5438uIBReiMEewJOvp6QeB4v2L07xsO0IYTHDoVQjSxnJRrOvcQGb3Jy5clJLI6eTn7AP98aTvuK3QqeKqH-r33SnENvJ4ZdjmFseyfWKaLcZZyM_zVS-y6NXESSaAwCpS2wniTq55BnphU3UWZ1h4LE13VBagfXIU741opATHJBBhtNKUE9U5kRQSdr5zDD19R1kCPSEO18AFSjThTXhlRMuArRnmhY0LMkfAkh-7mWgnur5x9Y9oU4mL-OCfH9Rh_Sgk0j0aiB-NkLo7PURn8LWoVuefBF5ylfuUbhqY7h_jVvmmzla37qpm6KHf12ytNgiipMCCq_SSQocNYbdXi-xPpF5d0z4-v_Li9rEWpqcQMKXVjfWC9CnYFdUahSuLCA0Tz3-VRS0nBHD8s2OipPVtlnWXNPDfFcYIGDwOEeJj3GM6_8GMSvzTOUJGaJVXEsJ9kCQnEdw3QP0BZxvtGvxY2swf4JcfKNDO9depXVy_40gx36ORXz08ml2hL_Q7kPPPZ-MacQkvUR4S4nDIt81S9Y6FUp8V5aDMkytV4bjMKnTSPAGi8i13S-_7U0L5zQljosV1Wr_pv-8OTKBc1BFD8dd_TF3vAnoevKDWi_bu4HQaWpRjMv0w2h4HARhclyPaeY');
    } else {    
      if(localStorage.getItem('selectedLanguage')) {
        this.$i18n.locale = localStorage.getItem('selectedLanguage');
      } else {
        localStorage.setItem('selectedLanguage', 'en');
        this.$i18n.locale = this.selectedLanguage;
      }

      const token = localStorage.getItem('xperttoken');
      const role = localStorage.getItem('xpertrole');
      const userid = localStorage.getItem('xpertuserid');

      if (window.location.href.includes("/admin") && role && role != 1) {
        window.location.href = "/";
      }

      if (token && localStorage.getItem('xpertcustomerselected') && localStorage.getItem('xpertcustomerselected') != null && localStorage.getItem('xpertcustomerselected') != '') {
        const instance = axios.create({
          baseURL: this.$baseURL,
          headers: {
            Authorization: `Bearer ${token}`,
            'Customer': localStorage.getItem('xpertcustomerselected')
          }
        });

        instance.get('/customer/type')
        .then(response => {
            this.customerType = response.data.type;
            localStorage.setItem('xpertcustomertype', response.data.type);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            // Handle the error
        });

        /* Get settings */
        instance.get('/admin/settings')
        .then(response => {
          console.log(response);

          this.settings = response.data.settings;

          console.log(this.settings.logo);

          if(this.settings.logo) {
            var image = document.getElementById('logoImg');
            image.src = this.settings.logo;
          }

          if(this.settings.application_name) {
            document.title = this.settings.application_name;
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          // Handle the error
        });

        /* Get unclaimed bookings */
        instance.get('admin/booking/unclaimed')
          .then(response => {
              console.log(response);

              this.unreadCountBookings = response.data.unreadCountBookings;

              const unreadCountBookingsEles = document.querySelectorAll('.unreadCountBookings');

              console.log(this.unreadCountBookings );

              if(this.unreadCountBookings != 0) {
                unreadCountBookingsEles.forEach(element => {
                  element.style.display = 'block';
                  element.textContent = this.unreadCountBookings;
                });
              }
          })
          .catch(error => {
              console.error('Error fetching data:', error);
              // Handle the error
          });

        /* Get unread */
        instance.get('chat/unread')
          .then(response => {
              console.log(response);

              this.unreadCount = response.data.unreadCount;

              const unreadcount = document.querySelectorAll('.unreadCount');

              console.log(this.unreadCount );

              if(this.unreadCount != 0) {
                unreadcount.forEach(element => {
                  element.style.display = 'block';
                  element.textContent = this.unreadCount;
                });
              }
          })
          .catch(error => {
              console.error('Error fetching data:', error);
              // Handle the error
          });

        /** Get profile */
        instance.get('/assets/profile')
          .then(response => {
            this.user = response.data.asset;

            const userimages = document.querySelectorAll('.right-side img');

            if (this.user.image) {
              userimages.forEach(element => {
                element.src = this.user.image;
              });
            }

            const usernames = document.querySelectorAll('.right-side .user_name .name');

            usernames.forEach(element => {
              element.textContent = this.user.title;
            });

            /* Disable asset menu on admin and no asset linked */
            if (this.user !== undefined && this.user !== null && typeof this.user === 'object' && 'id' in this.user) {
              const assetBlocks = document.querySelectorAll('.assetmenu');

              // Loop through the selected elements and set their display property to 'block'
              assetBlocks.forEach(element => {
                element.style.display = 'block';
              });
            } else {
              const assetBlocks = document.querySelectorAll('.assetmenu');

              // Loop through the selected elements and set their display property to 'block'
              assetBlocks.forEach(element => {
                element.style.display = 'none';
              });
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });

        /** Get all roles */
        instance.get('roles')
          .then(response => {
              this.roles = response.data.roles;
          })
          .catch(error => {
              console.error('Error fetching data:', error);
              // Handle the error
          });

        const elementsWithClass = document.querySelectorAll('.usermenu');

        // Loop through the selected elements and set their display property to 'block'
        elementsWithClass.forEach(element => {
          element.style.display = 'block';
        });
      } else {
        const elementsWithClass = document.querySelectorAll('.loginmenu');

        // Loop through the selected elements and set their display property to 'block'
        elementsWithClass.forEach(element => {
          element.style.display = 'block';
        });
      }

      if (role && role == 1) {
        const elementsWithClass2 = document.querySelectorAll('.adminmenu');

        // Loop through the selected elements and set their display property to 'block'
        elementsWithClass2.forEach(element => {
          element.style.display = 'block';
        });

        const roles = document.querySelectorAll('.role');

        // Loop through the selected elements and set their display property to 'block'
        roles.forEach(element => {
          element.textContent = 'Administrator';
        });
      }

      if(userid == 1) {
        const elementsWithClass2 = document.querySelectorAll('.sadminmenu');

        // Loop through the selected elements and set their display property to 'block'
        elementsWithClass2.forEach(element => {
          element.style.display = 'block';
        });

        const roles = document.querySelectorAll('.role');

        // Loop through the selected elements and set their display property to 'block'
        roles.forEach(element => {
          element.textContent = 'Super Administrator';
        });
      }
    }
  },
};
</script>
